import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-scroll";
import axios from "axios";
import Spinner from "./Spinner";
import SpinnerSmall from "../components/SpinnerSmall";
import dict from "../utils/dictionary";

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const Price = () => {
  const dictionary = dict()["price"];
  const products = {
    las: [
      {
        idVerbose: "las_cap",
        title: "Sin tocar",
        name: "Unranked LAS con Cápsulas Intactas",
        type: "normal-unranked",
        price: 8500,
        originalPrice: 8500,
        region: "las",
        capsuleType: "capsule",
        startBe: 0,
        endBe: 70000,
        description: ["Entrega inmediata", "Al menos nivel 30", "MMR virgen", "0% posibilidad de ban", "Email sin verificar", "Botín sin tocar"],
        link: "#",
      },
      // {
      //   idVerbose: "las_40",
      //   title: "Básica",

      //   name: "Unranked LAS 40.000+ Esencia",
      //   type: "normal-unranked",
      //   price: 1000,
      //   originalPrice: 1000,
      //   region: "las",
      //   capsuleType: "be",
      //   startBe: 40000,
      //   endBe: 50000,
      //   description: ["Al menos nivel 30", "MMR virgen", "0% posibilidad de ban", "Email sin verificar", "Garantía de por vida", "40.000+ Esencia Azul"],
      //   link: "#",
      // },
      // {
      //   idVerbose: "las_50",
      //   title: "Especial",

      //   name: "Unranked LAS 50.000+ Esencia",
      //   type: "normal-unranked",
      //   price: 1200,
      //   originalPrice: 1200,
      //   region: "las",
      //   capsuleType: "be",
      //   startBe: 50000,
      //   endBe: 60000,
      //   description: ["Al menos nivel 30", "MMR virgen", "0% posibilidad de ban", "Email sin verificar", "Garantía de por vida", "50.000+ Esencia Azul"],
      //   link: "#",
      // },
      // {
      //     idVerbose: 'las_60',
      //     title: 'Basica 2',
      //     name: 'Unranked LAS 60.000+ Esencia',
      //     type: 'normal-unranked',
      //     price: 700,
      //     originalPrice: 700,
      //     region: 'las',
      //     capsuleType: 'be',
      //     startBe: 60000,
      //     endBe: 70000,
      //     description: ['Al menos nivel 30', 'MMR virgen', '0% posibilidad de ban', 'Email sin verificar', 'Garantía de por vida', '60.000+ Esencia Azul'],
      //     link: '#',
      // },
    ],
    br: [
      {
        idVerbose: "br_cap",
        title: "Sin tocar",
        name: "Unranked BR con Cápsulas Intactas",
        type: "normal-unranked",
        price: 8500,
        originalPrice: 8500,
        region: "br",
        capsuleType: "capsule",
        startBe: 0,
        endBe: 70000,
        description: ["Entrega inmediata", "Al menos nivel 30", "MMR virgen", "0% posibilidad de ban", "Email sin verificar", "Botín sin tocar"],

        link: "#",
      },
      // {
      //   idVerbose: "br_40",
      //   title: "Basica",

      //   name: "Unranked BR 40.000+ Esencia",
      //   type: "normal-unranked",
      //   price: 1000,
      //   originalPrice: 1000,
      //   region: "br",
      //   capsuleType: "be",
      //   startBe: 40000,
      //   endBe: 50000,
      //   description: ["Al menos nivel 30", "MMR virgen", "0% posibilidad de ban", "Email sin verificar", "Garantía de por vida", "40.000+ Esencia Azul"],
      //   link: "#",
      // },
      // {
      //   idVerbose: "br_50",
      //   title: "Basica",

      //   name: "Unranked BR 50.000+ Esencia",
      //   type: "normal-unranked",
      //   price: 1200,
      //   originalPrice: 1200,
      //   region: "br",
      //   capsuleType: "be",
      //   startBe: 50000,
      //   endBe: 60000,
      //   description: ["Al menos nivel 30", "MMR virgen", "0% posibilidad de ban", "Email sin verificar", "Garantía de por vida", "50.000+ Esencia Azul"],
      //   link: "#",
      // },
      // {
      //     idVerbose: 'br_60',
      //     title: 'Basica 2',
      //     name: 'Unranked BR 60.000+ Esencia',
      //     type: 'normal-unranked',
      //     price: 700,
      //     originalPrice: 700,
      //     region: 'br',
      //     capsuleType: 'be',
      //     startBe: 60000,
      //     endBe: 70000,
      //     description: ['Al menos nivel 30', 'MMR virgen', '0% posibilidad de ban', 'Email sin verificar', 'Garantía de por vida', '60.000+ Esencia Azul'],
      //     link: '#',
      // },
    ],
  };
  const [coupon, setCoupon] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState({
    applied: false,
    discount: 0,
    price: 0,
  });
  const [error, setError] = useState("");
  const [generatingPaymentLoading, setGeneratingPaymentLoading] = useState(false);
  const [region, setRegion] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("email") ? localStorage.getItem("email") : "");
  const [quantity, setQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [generatePaymentButtonText, setGeneratePaymentButtonText] = useState({ canBuy: true, text: dictionary["continue"] });
  const [isFirstPart, setIsFirstPart] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [mercadoPagoAvailable, setMercadoPagoAvailable] = useState(false);

  const onChangeEmail = (event) => {
    setEmail(event.target.value.trim());
    if (!validateEmail(event.target.value.trim())) {
      setGeneratePaymentButtonText({ canBuy: false, text: dictionary["writeValidEmail"] });
    } else {
      setGeneratePaymentButtonText({ canBuy: true, text: dictionary["generatePayment"] });
    }
  };
  const onClickRegion = (region) => {
    setRegion(region);
    setSelectedProduct(null);
  };
  const onChangeCoupon = (event) => {
    setCoupon(event.target.value.toUpperCase());
  };
  const onClickCheckCoupon = () => {
    if (coupon) {
      // console.log('Check Coupon');
      checkCouponCode();
    }
  };
  const getStock = async (product) => {
    setIsLoading(true);
    try {
      let headers = {
        "Content-Type": "application/json",
      };
      // console.log("WE ARE SENDING THIS: ",product)
      let stockRequest = await axios.post(`/api/v1/stock/normal/check`, { ...product, price: quantity * product.originalPrice }, headers);
      let stockOutcome = stockRequest.data.msgCode;
      // console.log('stock request', stockOutcome)
      if (stockOutcome === "PRODUCT_OUT_OF_STOCK" || stockOutcome === "PRODUCT_NOT_FOUND") {
        setGeneratePaymentButtonText({ canBuy: false, text: dictionary["noStock"] });
        // console.log("Product out of stock")
      }

      if (stockOutcome === "PRODUCT_IN_STOCK") {
        setGeneratePaymentButtonText({ canBuy: true, text: dictionary["continue"] });
      }
    } catch (error) {
      setGeneratePaymentButtonText({ canBuy: false, text: dictionary["errorTryAgain"] });
    }
    setIsLoading(false);
  };
  const generatePreferenceMercadoPago = async () => {
    try {
      setGeneratingPaymentLoading(true);
      setError("");

      let headers = {
        "Content-Type": "application/json",
      };

      let stockRequest = await axios.post(`/api/v1/stock/normal/buy/mercadopago`, { ...selectedProduct, coupon: discountedPrice.applied ? coupon : "", quantity, email, price: quantity * selectedProduct.originalPrice }, headers);
      // console.log(stockRequest.data)
      let stockOutcome = stockRequest.data;

      if (stockOutcome.msgCode === "PRODUCT_OUT_OF_STOCK") {
        // console.log('Product out of stock [generatePreference]');
        setError(dictionary["errorNoStock"]);
      }
      if (stockOutcome.msgCode === "PAYMENT_CREATED") {
        // console.log("Payment created [generatePreference]", stockOutcome.data.transactionId)
        var script = document.createElement("script");

        script.src = `https://www.mercadopago.com.${dictionary["countryMpagoScript"]}/integrations/v1/web-payment-checkout.js`;
        script.type = "text/javascript";
        script.dataset.preferenceId = stockOutcome.data.transactionId;
        document.getElementById("mpago-checkout").innerHTML = "";
        document.querySelector("#mpago-checkout").appendChild(script);
        // setMercadoPagoAvailable(true);
        setMercadoPagoAvailable(true);
        // console.log("Created checkout button");
      }
    } catch (error) {
      setError(dictionary["errorPleaseTryAgain"]);
      // return 'ERROR';
    }
    setGeneratingPaymentLoading(false);
  };
  const checkCouponCode = async () => {
    // setGeneratingPaymentLoading(true);
    try {
      let headers = {
        "Content-Type": "application/json",
      };

      let couponRequest = await axios.post(`/api/v1/stock/coupon/check`, { coupon, quantity, price: selectedProduct.price, product: selectedProduct }, headers);
      // console.log(stockRequest.data)
      let couponOutcome = couponRequest.data;

      if (couponOutcome.msgCode === "INVALID_COUPON") {
        setError(dictionary["errorCouponInvalidForQuantity"]);
      }
      if (couponOutcome.msgCode === "INVALID_COUPON_EXPIRED") {
        setError(dictionary["errorCouponExpired"]);
      }
      if (couponOutcome.msgCode === "INVALID_COUPON_NOT_FOUND") {
        setError(dictionary["errorCouponDontExist"]);
      }
      if (couponOutcome.msgCode === "VALID_COUPON") {
        setSelectedProduct({ ...selectedProduct, price: couponOutcome.data.price, discount: couponOutcome.data.discount });
        setDiscountedPrice({ price: couponOutcome.data.price, discount: couponOutcome.data.discount, applied: true });
        setError("");
      }
    } catch (error) {
      setError(dictionary["errorPleaseTryAgain"]);
    }
  };

  const onClickProduct = async (product) => {
    if (!selectedProduct || product.idVerbose != selectedProduct.idVerbose) {
      setCoupon("");
      setDiscountedPrice({ discount: 0, price: 0, applied: false });
      setError("");

      setIsFirstPart(true);
      setMercadoPagoAvailable(false);
      setSelectedProduct({ ...product, price: quantity * product.originalPrice });
      getStock({ ...product, quantity: Number(quantity), price: quantity * product.originalPrice });
    }
  };
  const onChangeQuantity = (event) => {
    if ((Number.isInteger(Number(event.target.value)) || event.target.value === "") && event.target.value.length <= 2) {
      setDiscountedPrice({ discount: 0, price: 0, applied: false });
      setError("");
      setIsFirstPart(true);
      setMercadoPagoAvailable(false);

      setQuantity(event.target.value);
      setSelectedProduct({ ...selectedProduct, quantity: Number(event.target.value), price: event.target.value * selectedProduct.originalPrice });
      getStock({ ...selectedProduct, quantity: Number(event.target.value), price: event.target.value * selectedProduct.originalPrice });
    }
  };
  const onClickContinue = () => {
    setIsFirstPart(false);
    if (!validateEmail(email)) {
      setGeneratePaymentButtonText({ canBuy: false, text: dictionary["writeValidEmail"] });
    } else {
      setGeneratePaymentButtonText({ canBuy: true, text: dictionary["generatePayment"] });
    }
  };

  const onClickGeneratePaymentMercadoPago = () => {
    localStorage.setItem("email", email);
    generatePreferenceMercadoPago();
    // if (preferenceId != 'ERROR') {
    // 	setMercadoPagoAvailable(true);
    // } else {
    // 	console.log('ERROR IN OnClickGeneratePayment');
    // }
  };

  const options = {
    dots: false,
    arrows: true,
    accessibility: false,
    infinite: false,
    margin: 30,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let DataList =
    region &&
    products[region].map((val, i) => {
      return (
        <div className={`price-item d-flex ${selectedProduct && selectedProduct.idVerbose === val.idVerbose ? "price-selected" : ""} `} key={val.idVerbose}>
          <div className="price-block" style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/price-back.webp")` }}>
            <div className="price-type">
              <img style={{ width: "170px", height: "170px" }} src={`${process.env.PUBLIC_URL}/assets/images/${val.idVerbose.split("_")[1]}.webp`} alt="product"></img>
              {/* <h2>{val.title}</h2> */}
            </div>
            <div className="mrp">
              <h6 className="user-type">{val.name}</h6>
              <div className="price-devide"></div>
              <h2 style={selectedProduct && selectedProduct.idVerbose === val.id ? { color: "#448cda" } : {}}>
                {dictionary["localCurrencySymbol"]}
                {val.price}
              </h2>
              <h6 className="price-year">{dictionary["localCurrency"]}</h6>
              <div className="price-devide"></div>
            </div>
            <ul
              className="price-feature"
              // dangerouslySetInnerHTML={{ __html: val.features }}
            >
              {val.description.map((descItem, i) => (
                <li key={descItem}>
                  <i className="fas fa-check mr-2" style={{ color: "#568a56" }}></i>
                  <span style={{ color: "#353535" }}>{descItem}</span>
                </li>
              ))}
            </ul>
            <Link activeClass="active" to={"payment"} spy={true} smooth={true} offset={-430} duration={500} onClick={() => onClickProduct(val)}>
              <div className="btn btn-custom theme-color">{dictionary["chooseButton"]}</div>
            </Link>
          </div>
        </div>
      );
    });

  return (
    <>
      <div className="skins-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-text-center d-align-center">
              <h2 className="title" style={{ justifyContent: "center", display: "flex", color: "#dadada" }}>
                <span>{dictionary["accountsTitle"]}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <section id="price" className="price">
        <div className="container" style={{ textAlign: "center", paddingBottom: "30px" }}>
          <h2 style={{ fontSize: "3rem", lineHeight: "40px" }}>{dictionary["chooseYourRegion"]}</h2>
        </div>
        <div className="container" style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "50px", userSelect: "none" }}>
          <div style={{ cursor: "pointer", backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/las.webp")` }} className={`region-item col-3 ${region === "las" ? "region-selected" : ""}`} onClick={() => onClickRegion("las")}>
            <span>{dictionary["regions"]["las"]}</span>
          </div>
          <div style={{ cursor: "pointer", backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/br.webp")` }} className={`region-item col-3 ${region === "br" ? "region-selected" : ""}`} onClick={() => onClickRegion("br")}>
            <span>{dictionary["regions"]["br"]}</span>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Slider className="price-carousel owl-carousel owl-theme slick-margin" {...options}>
                {" "}
                {DataList}
              </Slider>
            </div>
          </div>
        </div>
        <div id="payment" className="container" style={{ marginTop: "50px" }}>
          {selectedProduct && (
            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
              {selectedProduct && (
                <div className="innerpage-decor d-none d-xl-block">
                  <div className="innerpage-faq1" style={{ bottom: "1px", right: "1px", top: "auto" }}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/money.webp`} alt="poro with essence" />
                  </div>
                  <div className="innerpage-faq2">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/gold.webp`} alt="gold" />
                  </div>
                </div>
              )}
              {isLoading ? (
                <div style={{ height: "475px", alignItems: "center", display: "flex" }}>
                  <Spinner />
                </div>
              ) : (
                <div className="payment-skin" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <h1 style={{ lineHeight: "40px" }}>{dictionary["makeYourPayment"]}</h1>
                  <h2 style={{ margin: "0", fontSize: "20px", textAlign: "center" }}>{selectedProduct.name}</h2>
                  <div className="skins-description-payment">
                    {selectedProduct.description.map((desc) => (
                      <div key={desc}>
                        <i className="fas fa-check mr-2" style={{ color: "#568a56" }}></i>
                        <span style={{ color: "#b7b7b7" }}>{desc}</span>
                      </div>
                    ))}
                  </div>
                  <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                    <div style={{}}>
                      <h2 style={{ margin: "0px", fontSize: "20px" }}>
                        {dictionary["price"]}:{" "}
                        <span style={discountedPrice.applied ? { textDecoration: "line-through" } : {}}>
                          {dictionary["localCurrencySymbol"]}
                          {selectedProduct.originalPrice * quantity}
                        </span>{" "}
                        {discountedPrice.applied ? (
                          <>
                            <span>
                              {dictionary["localCurrencySymbol"]}
                              {discountedPrice.price}
                            </span>
                            <span style={{ fontSize: "12px" }}> (-{discountedPrice.discount}%)</span>
                          </>
                        ) : (
                          <span></span>
                        )}
                        {/* // `$${discountedPrice.price}(-${discountedPrice.discount}%)` : ''} */}
                      </h2>
                    </div>
                    <div style={{ display: "flex" }}>
                      <h2 style={{ fontSize: "10px", marginRight: "10px" }}>{dictionary["quantity"]}</h2>
                      <input disabled={mercadoPagoAvailable ? true : false} onChange={onChangeQuantity} value={quantity} style={{ height: "min-content", width: "30px" }}></input>
                    </div>
                  </div>
                  {/* <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", marginTop: "20px" }}>
                    <h2 style={{ fontSize: "20px", padding: "0px 20px 0px 0px", margin: "0px" }}>{dictionary["coupon"]}</h2>
                    <input maxLength={16} value={coupon} placeholder={dictionary["useCouponToGetDiscount"]} onChange={onChangeCoupon} style={{ height: "min-content", width: "100%" }}></input>
                    <div onClick={onClickCheckCoupon} style={{ marginLeft: "20px", height: "min-content" }} className={`btn btn-custom theme-color ${mercadoPagoAvailable ? "btn-disabled" : ""}`}>
                      {dictionary["use"]}
                    </div>
                  </div> */}
                  {isFirstPart ? (
                    // 	<Link activeClass='active' to={'payment'} spy={true} smooth={true} offset={-220} duration={500} onClick={() => onClickProduct(val)}>
                    // 	<div className='btn btn-custom theme-color'>Elegir</div>
                    // </Link>

                    <div style={{ width: "100%" }} className={`btn btn-custom theme-color mt-3 ${!generatePaymentButtonText.canBuy ? "btn-disabled" : ""}`} onClick={() => onClickContinue()}>
                      {generatePaymentButtonText.text}
                    </div>
                  ) : (
                    <>
                      {!mercadoPagoAvailable && !generatingPaymentLoading && (
                        <>
                          <input placeholder={dictionary["yourEmail"]} onChange={onChangeEmail} value={email}></input>

                          <div style={{ width: "100%" }} className={`btn btn-custom theme-color mt-3 ${!validateEmail(email) ? "btn-disabled" : ""}`} onClick={() => onClickGeneratePaymentMercadoPago()}>
                            {generatePaymentButtonText.text}
                          </div>
                        </>
                      )}
                      <div id="mpago-checkout" style={{ display: "flex", justifyContent: "center" }}></div>
                      {generatingPaymentLoading && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <SpinnerSmall />
                        </div>
                      )}
                    </>
                  )}
                  {error && (
                    <div className="error-message">
                      <i style={{ color: "#d42121" }} className="fas fa-times mr-2"></i>
                      <span>{error}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Price;
